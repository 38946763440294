import { getToken, getUser } from "../commons";
import { getAPICall } from "./axiosCommons";

export const getTrackerReport = async (data) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "trackers/export",
      {
        requester: getUser(),
        token: getToken(),
        classification_type: data.classification_type,
        activation_type: data.activation_type,
        date_from: data.date_from,
        date_to: data.date_to,
      }
    );
    //// console.log(response.data);
    return { data: response.data };
  } catch (error) {
    //// console.log(error.response);
    return { error: error.response };
  }
};
