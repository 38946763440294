import React, { useState, useEffect } from "react";

// CSS styles
import "bootstrap/dist/css/bootstrap.min.css";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "react-datepicker/dist/react-datepicker.css";
import "./App.css";

// Pages
import Login from "./pages/Login/Login";
import Patients from "./pages/Reports/Patients";
import Consumers from "./pages/Reports/Consumers";

// User Page
import UserManage from "./pages/User/UserManage";
import AddUser from "./pages/User/AddUser";
import EditUser from "./pages/User/EditUser";

// Attendance
import AttendanceManage from "./pages/Attendance/AttendanceManage";

// Public Map
import PublicMap from "./pages/PublicMap/PublicMap";

// Components
import SideBar from "./components/Sidebar/SideBar";

// Routes
import { BrowserRouter, Routes, Route } from "react-router-dom";

// API
import { getRole } from "./utils/commons.js";
import Trackers from "./pages/Reports/Trackers";
import FormManage from "./pages/Form/FormManage";
import UserManageWithAction from "./pages/User/UserManageWithAction";

function App() {
  const [inactive, setInactive] = useState(true);

  const toggle = () => {
    setInactive(!inactive);
  };

  // Check if logged in
  useEffect(() => {
    const role = getRole();
    if (
      role == null &&
      window.location.pathname !== "/" &&
      window.location.pathname !== "/public_map"
    ) {
      window.location.href = "/";
    } else if (role !== null && window.location.pathname === "/") {
      window.location.href = "/reports/patients";
    }
  }, [window.location.pathname, localStorage.getItem("role")]);

  return (
    <BrowserRouter>
      {/* {getRole() !== null && <CustomNavBar toggle={toggle} />} */}
      <div className="d-flex flex-row">
        {getRole() !== null && <SideBar toggle={toggle} isOpen={inactive} />}
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>
        <Routes>
          <Route
            path="/tracker-reports"
            element={<Trackers setInactive={setInactive} />}
          />
          <Route
            path="/reports/patients"
            element={<Patients setInactive={setInactive} />}
          />
          <Route
            path="/reports/consumers"
            element={<Consumers setInactive={setInactive} />}
          />
        </Routes>
        <Routes>
          <Route path="/user" element={<UserManageWithAction />} />
        </Routes>
        <Routes>
          <Route path="/user/add" element={<AddUser />} />
        </Routes>
        <Routes>
          <Route path="/user/edit/:id" element={<EditUser />} />
        </Routes>
        <Routes>
          <Route path="/attendance" element={<AttendanceManage />} />
        </Routes>
        <Routes>
          <Route path="/forms" element={<FormManage />} />
        </Routes>
        <Routes>
          <Route
            path="/public_map"
            element={<PublicMap setInactive={setInactive} />}
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
