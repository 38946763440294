const container = {
  maxWidth: "500px",
  height: "auto",
  borderRadius: "10px",
  boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
  backgroundColor: "#fff",
};

const button = {
  width: "100%",
  height: "100%",
  borderRadius: "10px",
  boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
  backgroundColor: "#076535",
  color: "#fff",
};

const styles = {
  container,
  button,
};

export default styles;
