import React, { useState, useEffect } from "react";
import CustomToast from "../../components/CustomToast.js";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-datepicker";
import { searchForms, searchStores } from "../../utils/api/store";
import { Col, Row } from "react-bootstrap";
import "./Reports.css";
import { getTrackerReport } from "../../utils/api/reportsAPI.js";
import { getRole } from "../../utils/commons.js";

function Trackers({ setInactive }) {
  const [details, setMessage] = useState({ message: "", type: "" });
  const [showToast, setShowToast] = useState(false);
  const [data, setData] = useState([]);
  const [mapUrls, setMapUrls] = useState([]);
  const [searchData, setSearchData] = useState({
    tracker_type: "",
    date_from: "",
    date_to: "",
    url: "",
    classification_type: "",
    activation_type: "",
  });
  const [isClicked, setIsClicked] = useState(false);
  const [show, setShow] = useState(false);
  const [exportable, setExportable] = useState({ items: [], headers: [] });
  const [backdrop, setBackdrop] = useState(true);
  const [mapData, setMapData] = useState({
    lat: 0,
    lng: 0,
    address: "",
    store_name: "",
    photo: "",
  });
  const [signatureURL, setSignatureURL] = useState("");
  const [showSignature, setShowSignature] = useState(false);

  async function fetchReport() {
    setSearchData({ ...searchData, url: "" });
    const response = await getTrackerReport(searchData);
    // console.log(response.data);
    if (response.data) {
      setSearchData({ ...searchData, url: response.data.url });
    }
  }

  function handleDetailChange(e) {
    const { name, value } = e.target;
    setSearchData({ ...searchData, [name]: value });
    if (value === "ANS-NKA KPI") {
      setSearchData({
        ...searchData,
        tracker_type: value,
        classification_type: "ans",
        activation_type: "nka",
      });
    }
    if (value === "ANS-LKA KPI") {
      setSearchData({
        ...searchData,
        tracker_type: value,
        classification_type: "ans",
        activation_type: "lka",
      });
    }
    if (value === "FULLBOOTH KPI") {
      setSearchData({
        ...searchData,
        tracker_type: value,
        classification_type: "fullbooth",
        activation_type: "",
      });
    }
  }

  return (
    <div className="container-fluid">
      <div className="row text-center mt-4">
        <div className="col-md-12">
          <p className="h1 title">TRACKER REPORTS</p>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-3">
          <Form.Select
            size="sm"
            name="tracker_type"
            onChange={handleDetailChange}
          >
            <option>Select</option>
            {getRole() === "nka admin" && (
              <>
                <option>ANS-NKA KPI</option>
                <option>FULLBOOTH KPI</option>
              </>
            )}
            {getRole() === "lka admin" && (
              <>
                <option>ANS-LKA KPI</option>
                <option>FULLBOOTH KPI</option>
              </>
            )}
            {getRole() !== "lka admin" && getRole() !== "nka admin" && (
              <>
                <option>ANS-NKA KPI</option>
                <option>ANS-LKA KPI</option>
                <option>FULLBOOTH KPI</option>
              </>
            )}
          </Form.Select>
        </div>
        <div className="col-3">
          <Form.Control
            type="date"
            size="sm"
            name="date_from"
            onChange={handleDetailChange}
          />
        </div>
        <div className="col-3">
          <Form.Control
            type="date"
            size="sm"
            name="date_to"
            onChange={handleDetailChange}
          />
        </div>
        <div className="col">
          <Button
            size="sm"
            variant="primary"
            className="w-100 m-0"
            onClick={fetchReport}
            disabled={
              searchData.tracker_type === "" ||
              searchData.tracker_type === "Select" ||
              searchData.date_to === "" ||
              searchData.date_from === ""
            }
          >
            Generate
          </Button>
        </div>
      </div>
      <div className="row d-flex justify-content-center mt-5 pt-5">
        {/* <div className="col-sm-12 col-md-4 align-center text-center">
          <Button size="sm" className="tracker-btn">
            ANS-NKA KPI
          </Button>
        </div> */}
        {/* <div className="col-sm-12 col-md-4 text-center">
          <Button size="sm" className="tracker-btn">
            ANS-LKA KPI
          </Button>
        </div> */}
        {searchData.url !== "" && (
          <div className="col-sm-12 col-md-4 text-center">
            <Button
              size="sm"
              className="tracker-btn"
              onClick={() => window.open(searchData.url)}
            >
              Download Report
            </Button>
          </div>
        )}
      </div>
      <CustomToast
        type={details.type}
        message={details.message}
        title="Store"
        show={showToast}
        setShow={setShowToast}
      />
    </div>
  );
}
export default Trackers;
