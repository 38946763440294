import React, { useState, useEffect } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import styles from "./CustomTable.styles.js";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import PDFDownload from "../PDFPrint/PDFDownload.js";

const CustomTable = ({
  size,
  items,
  mapUrls = [],
  setItems,
  headers,
  itemsPerPage,
  title,
  exportable,
  photoIndex,
  beginningIndex = 0,
  endIndex = items.length,
  handleExport,
  type,
  setPage,
  handlePaginate,
}) => {
  const [sorting, setSorting] = useState({
    field: headers[0],
    ascending: false,
  });

  function Items({ currentItems }) {
    return (
      <>
        {currentItems.map((row, index) => {
          return (
            <Tr key={index}>
              {row.map((cell, index) => {
                return (
                  <Td key={index}>
                    {index !== 6 && index !== 8 && index !== 12 && index !== 28 ? cell + " " : cell}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </>
    );
  }

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount =
    type === "form-report"
      ? Math.ceil(size / itemsPerPage)
      : Math.ceil(items.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    if (type === "form-report") {
      var pagecount = parseInt(event.selected) + 1;
      setPage(pagecount);
      handlePaginate(parseInt(event.selected) + 1);
    }

    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  const applySorting = (key, ascending) => {
    setSorting({ field: key, ascending: ascending });
  };

  useEffect(() => {
    // Copy array to prevent data mutation
    const currentItemsCopy = [...items];

    // Apply sorting
    const sortedCurrentItems = currentItemsCopy.sort((a, b) => {
      // Check what index is the key in the headers array
      const keyIndex = headers.indexOf(sorting.field);
      // sort the item array based on the key index
      if (a[keyIndex] < b[keyIndex]) {
        return sorting.ascending ? -1 : 1;
      }
    });

    // Replace currentItems with sorted currentItems
    setItems(
      // Decide either currentItems sorted by ascending or descending order
      sorting.ascending ? sortedCurrentItems : sortedCurrentItems.reverse()
    );
  }, [sorting]);

  const handleOnExport = () => {
    // convert image url to image file
    // combine header and data
    const data = [
      headers.filter((data) => (data.toLowerCase() !== "action" && data.toLowerCase() !== "actions")),
      ...exportable.items,
    ];
    const wb = XLSX.utils.book_new();

    const wsAll = XLSX.utils.aoa_to_sheet(data);
    // Update all column width to fit the content
    wsAll["!cols"] = [...Array(headers.length)].map(() => ({ wch: 20 }));

    XLSX.utils.book_append_sheet(wb, wsAll, exportable.name);
    XLSX.writeFile(wb, `${title} ${new Date().toLocaleDateString()}.xlsx`);
  };

  return (
    <div className="mx-5">
      <button onClick={type === "form-report" ? handleExport : handleOnExport}>
        Export Excel
      </button>
      {/* {(title === "Stores" || title === "Attendances") && (
        <PDFDownload
          data={exportable}
          mapUrls={mapUrls}
          fileName={title}
          beginningIndex={beginningIndex}
          endIndex={endIndex}
          photoIndex={photoIndex}
        />
      )} */}
      <Table style={styles.table} className="mb-2">
        <Thead>
          <Tr className="text-center">
            {headers.map((val, index) => {
              return (
                <Th
                  key={index}
                  onClick={() => {
                    applySorting(val, !sorting.ascending);
                  }}
                >
                  {val}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody className="text-center">
          {items.length > 0 && <Items currentItems={currentItems} />}
          {items.length == 0 && (
            <Tr>
              <Td colSpan={headers.length}>No data</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <ReactPaginate
        previousLabel="Previous"
        nextLabel="Next"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageCount={pageCount}
        pageRangeDisplayed={5}
        marginPagesDisplayed={5}
        containerClassName="pagination justify-content-center"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        activeClassName="active"
        onPageChange={handlePageClick}
        setPage={setPage}
      />
    </div>
  );
};

export default CustomTable;
