import CustomMap from "../../components/CustomMap/CustomMap";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

function PublicMap({ setInactive }) {
  const [searchParams] = useSearchParams();
  console.log(searchParams.get("test"));

  useEffect(() => {
    setInactive();
  }, [setInactive]);

  const mapData = {
    lat: searchParams.get("lat"),
    lng: searchParams.get("lng"),
    address: searchParams.get("address"),
    image: searchParams.get("image"),
    store_name: searchParams.get("store_name"),
    photo: searchParams.get("photo"),
  };

  return (
    <div className='container-fluid p-0 m-0'>
      <CustomMap mapData={mapData} fullScreen={true} />
    </div>
  );
}
export default PublicMap;
