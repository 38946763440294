import { getRole, getToken, getUser } from "../../utils/commons";
import { getAPICall } from "./axiosCommons";
import Moment from "moment";

// GET NGROK
export const searchForms = async (datefrom, dateto, encoded_by, role, page) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "forms/search",
      {
        requester: getUser(),
        token: getToken(),
        date_from: Moment(datefrom).format("YYYY-MM-DD"),
        date_to: Moment(dateto).format("YYYY-MM-DD"),
        encoded_by: encoded_by,
        role: role,
        user_role: getRole(),
        page: page,
      }
    );
    //// console.log(response.data);
    return { data: response.data };
  } catch (error) {
    //// console.log(error.response);
    return { error: error.response };
  }
};
export const exportForm = async (datefrom, dateto, encoded_by, role) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "forms/export",
      {
        requester: getUser(),
        token: getToken(),
        date_from: Moment(datefrom).format("YYYY-MM-DD"),
        date_to: Moment(dateto).format("YYYY-MM-DD"),
        encoded_by: encoded_by,
        role: role,
        user_role: getRole(),
      }
    );
    //// console.log(response.data);
    return { data: response.data };
  } catch (error) {
    //// console.log(error.response);
    return { error: error.response };
  }
};
