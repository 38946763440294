import { getRole, getToken, getUser } from "../../utils/commons";
import { getAPICall, postAPICall } from "./axiosCommons";
import Moment from "moment";

// GET NGROK
export const searchUsers = async (username) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "users/search",
      {
        requester: getUser(),
        token: getToken(),
        username: username,
        user_role: getRole(),
      }
    );
    //// console.log(response.data);
    return { data: response.data };
  } catch (error) {
    //// console.log(error.response);
    return { error: error.response };
  }
};

export const getUserDetail = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "users/get",
      {
        requester: getUser(),
        token: getToken(),
        user_id: id,
      }
    );
    //// console.log(response.data);
    return { data: response.data };
  } catch (error) {
    //// console.log(error.response);
    return { error: error.response };
  }
};

export const addUser = async (data) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "users/add",
      {
        requester: getUser(),
        token: getToken(),
        role: data.role,
        // type: data.type.toLowerCase(),
        first_name: data.first_name,
        last_name: data.last_name,
        username: data.username,
        area_code: data.area_code,
        daily_quota: data.daily_quota,
      }
    );
    return { data: response };
  } catch (error) {
    return { error: error.response };
  }
};

export const updateUser = async (data) => {
  // console.log(data)
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "users/update",
      {
        requester: getUser(),
        token: getToken(),
        user_id: data.id,
        role: data.role,
        // type: data.type.toLowerCase(),
        first_name: data.first_name,
        last_name: data.last_name,
        username: data.username,
        area_code: data.area_code,
        daily_quota: data.daily_quota,
      }
    );

    return { data: response };
  } catch (error) {
  // console.log(error)
  return { error: error.response };
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "users/delete",
      {
        requester: getUser(),
        token: getToken(),
        user_id: id,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};
