import Moment from "moment";
import { getToken, getUser } from "../commons";
import { getAPICall, postAPICall } from "./axiosCommons";

export const searchFormResponses = async (data, page) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "forms/search",
      {
        requester: getUser(),
        token: getToken(),
        date_from: Moment(data.dateFrom).format("YYYY-MM-DD"),
        date_to: Moment(data.dateTo).format("YYYY-MM-DD"),
        encoded_by: data.agent,
        page: page,
      }
    );
    //// console.log(response.data);
    return { data: response.data };
  } catch (error) {
    //// console.log(error.response);
    return { error: error.response };
  }
};


export const exportFormResponses = async (data) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "forms/export",
      {
        requester: getUser(),
        token: getToken(),
        date_from: Moment(data.dateFrom).format("YYYY-MM-DD"),
        date_to: Moment(data.dateTo).format("YYYY-MM-DD"),
        encoded_by: data.agent,
        // role: role,
        // user_role: getRole(),
      }
    );
    //// console.log(response.data);
    return { data: response.data };
  } catch (error) {
    //// console.log(error.response);
    return { error: error.response };
  }
};