import moment from "moment";
import { logoutUser } from "./api/authentication";

/***************************
 * Common Utilities
 ***************************/

export const refreshPage = () => {
  window.location.reload();
};

export const getTime = (date) => {
  return date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

export const formatDateWithTime = (date) => {
  var formatDate = new Date(date);
  var stringDate = formatDate.toDateString().split(" ");
  return (
    stringDate[1] +
    " " +
    stringDate[2] +
    ", " +
    stringDate[3] +
    " " +
    formatDate.toLocaleTimeString().replace(/(.*)\D\d+/, "$1")
  );
};

export const subtractMonths = (numOfMonths, date = new Date()) => {
  date.setMonth(date.getMonth() - numOfMonths);
  return date.toISOString().split("T")[0];
};

export const getTodayDate = () => {
  return new Date();
};

export const getTodayDateISO = () => {
  let deets = new Date();
  let date = deets.toLocaleString("en-US", { timeZone: "Asia/Manila" });
  return moment(date).format("yyyy-MM-DD");
};

export const getAge = (dateString) => {
  var today = new Date();
  var birthdate = new Date(dateString);
  var age = today.getFullYear() - birthdate.getFullYear();
  var m = today.getMonth() - birthdate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
    age--;
  }
  return age;
};

export const formatDate = (date) => {
  if (date) {
    var stringDate = date.split("-");
    return stringDate[1] + "/" + stringDate[2] + "/" + stringDate[0];
  }
};

export const formatDateNoTime = (date) => {
  var formatDate = new Date(date);
  var stringDate = formatDate.toDateString().split(" ");

  return stringDate[1] + " " + stringDate[2] + ", " + stringDate[3];
};

// example parameter: "2022-10-08 00:00:00"
export const formatDateSlash = (date) => {
  var stringDate = date.split(" ");
  var _cleaned = stringDate[0].split("-");

  return _cleaned[1] + "/" + _cleaned[2] + "/" + _cleaned[0];
};

export const formatMDY = (date) => {
  var stringDate = date.split("-");
  return stringDate[1] + "-" + stringDate[2] + "-" + stringDate[0];
};

export const validateUsername = (username) => {
  if (username === "") {
    return true;
  } else {
    var filter =
      /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return String(username).search(filter) != -1;
  }
};

export const isClosingTime = (time) => {
  var format = "hh:mm:ss";
  var endTime = moment(time, format);
  return moment().isAfter(endTime);
};

export const isNotClosingDate = (date) => {
  return moment().isBefore(moment(date).add(1, "d"));
};

export const validateContact = (num) => {
  var mobileFilter = /((^(\+)(\d){12}$)|(^\d{11}$))/;
  var telFilter = /^[0-9]+(-[0-9]+)+$/;

  if (
    String(num).search(mobileFilter) != -1 ||
    String(num).search(telFilter) != -1
  ) {
    return true;
  } else {
    return false;
  }
};

export const dateFormat = (date) => {
  return moment(date).format("MMM DD, YYYY");
};

export const formatYDM = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const numberFormat = (data) => {
  return parseFloat(data).toLocaleString(undefined, {
    minimumFractionDigits: 2,
  });
};

export const numberFormatInt = (data) => {
  return parseFloat(data.replace(/,/g, ""));
};

export const formatNum = (num) => {
  return parseFloat(Math.round(num * 100) / 100).toFixed(2);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const TokenExpiry = async (error) => {
  if (error.error.status === 401 || error.status === 401) {
    await logoutUser();
    removeUserSession();
    setTimeout(() => refreshPage(), 1000);
  }
};

/***************************
 * Local Storage Utilities
 ***************************/

//return user data from local storage
export const getUser = () => {
  return localStorage.getItem("user");
};

//return user name from local storage
export const getName = () => {
  return localStorage.getItem("name");
};

//return user type from local storage
export const getRole = () => {
  return localStorage.getItem("role");
};

//return token from local storage
export const getToken = () => {
  return localStorage.getItem("token");
};

//remove user details from local storage
export const removeUserSession = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("name");
  localStorage.removeItem("role");
  localStorage.removeItem("token");
};

export const isAdmin = () => {
  return getRole() === "admin";
};
