import React, { useState, useEffect } from "react";
import CustomToast from "../../components/CustomToast.js";
import CustomTable from "../../components/CustomTable/CustomTable.js";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-datepicker";
import { searchAttendance } from "../../utils/api/attendance";
import moment from "moment";
import { Image } from "@chakra-ui/image";
import "./User.css"
import { deleteUser, searchUsers } from "../../utils/api/user.js";

function UserManageWithAction() {
  const [details, setMessage] = useState({ message: "", type: "" });
  const [showToast, setShowToast] = useState(false);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({
    agent: "",
    dateTo: new Date(),
    dateFrom: new Date(),
  });
  const [show, setShow] = useState(false);
  const [image, setImage] = useState("");
  const [fileName, setFileName] = useState("");
  const [exportable, setExportable] = useState({ items: [], headers: [] });
  const [width, setWidth] = useState(window.innerWidth);
  const [user, setUser] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  const headers = [
    "Role",
    "First Name",
    "Last Name",
    "Username",
    "Area Code",
    "Daily Quota",
    "Actions"
  ];

  const exportHeaders = [
    "Role",
    "First Name",
    "Last Name",
    "Username",
    "Area Code",
    "Daily Quota",
  ];

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const handleClose = () => setShowConfirmation(false);
  
  const handleDeleteUser = async (user) => {
    setUser(user);
    setShowConfirmation(true);
  };

  function renderActionButton(item) {
    // Return the button element to be rendered in the 7th column.
    return (
      // <Td className="justify-content-center">
      <div className="justify-content-center">
        <Button
          variant="primary"
          onClick={() => (window.location.href = `/user/edit/${item.id}`)}
        >
          Edit
        </Button>
        <Button
            className="btn-red"
            variant="secondary"
            onClick={() => handleDeleteUser(item)}
            style={{ backgroundColor: "red" }}
        >
          Delete
        </Button>
      </div>
      // </Td>
    );
  }

  const proceedToDelete = async () => {
    const response = await deleteUser(user.id);
    if (response?.data) {
      setMessage({ message: "User deleted successfully", type: "success" });
      setShowToast(true);
    } else {
      setMessage({ message: "Error deleting user", type: "danger" });
      setShowToast(true);
    }

    handleSearch();
    setShowConfirmation(false);
  };


  const handleSearch = async () => {
    const response = await searchUsers(searchData.username);

    if (response?.data?.data) {
      let data = response.data.data;
      let dataToDisplay = [];
      let exportableData = [];
      // Loop through the data and push it to the dataToDisplay array
      data.forEach((item) => {
        // get the data in each object
        let data = [];
        let exportableTemp = [];

        data.push(item.role.toString());
        data.push(item.first_name ? item.first_name.toString() : "");
        data.push(item.last_name ? item.last_name.toString() : "");
        data.push(item.username ? item.username.toString() : "");
        data.push(item.area_code ? item.area_code.toString() : "");
        data.push(item.daily_quota ? item.daily_quota.toString() : "");
        data.push(renderActionButton(item));


        exportableTemp.push(item.role.toString());
        exportableTemp.push(item.first_name ? item.first_name.toString() : "");
        exportableTemp.push(item.last_name ? item.last_name.toString() : "");
        exportableTemp.push(item.username ? item.username.toString() : "");
        exportableTemp.push(item.area_code ? item.area_code.toString() : "");
        exportableTemp.push(item.daily_quota ? item.daily_quota.toString() : "");

        dataToDisplay.push(data);
        exportableData.push(exportableTemp);
      });
      setExportable({ items: exportableData, headers: exportHeaders });
      setData(dataToDisplay);
    } else {
      let responseMessage = response?.data?.messages?.error;
      setMessage({
        message: responseMessage ?? "Something went wrong.",
        type: "danger",
      });
      setShowToast(true);
    }
  };
  console.log(data)


  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <div className="container-fluid">
        <div className="row text-center mt-4">
            <div className="col-md-12">
            <p className="h1 title">USERS</p>
            </div>
        </div>
        <div className="row mb-3">
            <div className="mt-3 col-12 col-sm-9">
            <InputGroup className="mb-sm-3">
                <Form.Control
                placeholder="Search"
                aria-label="Username"
                value={searchData.username}
                onChange={(e) =>
                    setSearchData({ ...searchData, username: e.target.value })
                }
                />
            </InputGroup>
            </div>

            <div className="mt-3 col-12 col-sm-3">
            <Button
                variant="primary"
                className="w-100 m-0"
                onClick={handleSearch}
            >
                Search
            </Button>
            </div>

            <div className="mt-3 col-12 col-sm-3 ms-auto">
            <button
                variant="primary"
                className="w-100 m-0"
                onClick={() => (window.location.href = "/user/add")}
            >
                Add User
            </button>
            </div>
        </div>
        <div className="row justify-content-center">
            <CustomTable
              items={data}
              setItems={setData}
              headers={headers}
              itemsPerPage={10}
              title="Users"
              exportable={exportable}
              photoIndex={8}
              type="regular"
            />
        </div>
        <CustomToast
            type={details.type}
            message={details.message}
            title="User"
            show={showToast}
            setShow={setShowToast}
        />
        <Modal show={showConfirmation} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Confirmation </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete{" "}
                {`${user.first_name} ${user.last_name}`}?
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={proceedToDelete}
                    ref={(el) =>
                    el && el.style.setProperty("background-color", "red", "important")
                    }
                >
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
  );
}

export default UserManageWithAction;
