import React, { useState, useEffect } from "react";
import CustomToast from "../../components/CustomToast.js";
import CustomTable from "../../components/CustomTable/CustomTable.js";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-datepicker";
import { searchAttendance } from "../../utils/api/attendance";
import moment from "moment";
import { Image } from "@chakra-ui/image";
import { exportFormResponses, searchFormResponses } from "../../utils/api/formsApi.js";
import { formatDate, formatDateWithTime } from "../../utils/commons.js";

function FormManage() {
  const [details, setMessage] = useState({ message: "", type: "" });
  const [showToast, setShowToast] = useState(false);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({
    agent: "",
    dateTo: new Date(),
    dateFrom: new Date(),
  });
  const [show, setShow] = useState(false);
  const [image, setImage] = useState("");
  const [fileName, setFileName] = useState("");
  const [exportable, setExportable] = useState({ items: [], headers: [] });
  const [width, setWidth] = useState(window.innerWidth);
  const [page, setPage] = useState("1");
  const [size, setSize] = useState("");

  const headers = [
    "Name",
    "Merchant Name",
    "DBA + Outlet",
    "City",
    "Brgy",
    "Postal Code",
    "Slot/Floor",
    "Building",
    "Coordinates",
    "Maya Merchant",
    "Maya Merchant MID - SQR",
    "Maya Merchant MID - Terminal",
    "Terminal SN",
    "SQR Store Code",
    "Name",
    "Number",
    "Email",
    "Maya Solutions",
    "Terminal/s Available 1",
    "Terminal/s Available 2",
    "Terminal/s Available 3",
    "Terminal/s Available 4",
    "Terminal/s Available 5",
    "Terminal/s Available 6",
    "Terminal/s Available 7",
    "Terminal/s Available 8",
    "Terminal/s Available 9",
    "Terminal/s Available 10",
    "Terminal/s Available 11",
    "Terminal/s Available 12",
    "Terminal/s Available 13",
    "Terminal/s Available 14",
    "Competitor Solution Device 1",
    "Competitor Solution Device 2",
    "Competitor Solution Device 3",
    "Competitor Solution Device 4",
    "Competitor Solution Device 5",
    "Competitor Solution Device 6",
    "Competitor Solution Device 7",
    "Competitor Solution Device 8",
    "Competitor Solution Device 9",
    "Competitor Solution Device 10",
    "Competitor Solution Device 11",
    "Maya Transactions",
    "Gcash Transaction",
    "Cash Transaction",
    "Other Transaction",
    "How Many",
    "Own Merchandising Material 1",
    "Own Merchandising Material 2",
    "Own Merchandising Material 3",
    "Own Merchandising Material 4",
    "Own Merchandising Material 5",
    "Own Merchandising Material 6",
    "Awareness of QRPh",
    "Merchandising Restrictions",
    "Merchant Concern 1",
    "Merchant Concern 2",
    "Merchant Concern 3",
    "Merchant Concern 4",
    "Merchant Concern 5",
    "Merchant Concern 6",
    "Merchant Concern 7",
    "Merchant Concern 8",
    "Merchant Concern 9",
    "Merchant Concern 10",
    "Merchant Concern 11",
    "Merchant Concern 12",
    "Servicing 1",
    "Servicing 2",
    "Servicing 3",
    "Servicing 4",
    "Servicing 5",
    "Servicing 6",
    "Servicing 7",
    "Servicing 8",
    "Servicing 9",
    "Replacement 1",
    "Replacement 2",
    "Replacement 3",
    "Replacement 4",
    "Merchandising Request 1",
    "Merchandising Request 2",
    "Merchandising Request 3",
    "Merchandising Request 4",
    "Merchandising Request 5",
    "Merchandising Request 6",
    "Merchandising Request 7",
    "Retraining Concern",
    "Done/Resolved",
    "Ongoing Escalation/Remarks",
    "Encoded On",
  ];

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const handleClose = () => setShow(false);
  const handleShow = (image, file) => {
    setFileName(file);
    setImage(image);
    setShow(true);
  };
  

  function renderOtherCompetitorAttachments(item) {
    return (
    <div className="justify-content-center row">
      {item.other_competitor_details && (
        <span className="">{item.other_competitor_details}</span>
      )}
      {item.other_competitor_1 && (
        <a
          className="font-regular font-10"
          href={item.other_competitor_1}
          target="_blank"
        >
          {item.other_competitor_1}
        </a>
      )}
      {item.other_competitor_2 && (
        <a
          className="font-regular font-10"
          href={item.other_competitor_2}
          target="_blank"
        >
          {item.other_competitor_2}
        </a>
      )}
    </div>
    );
  }

  const handleSearch = async () => {
    const response = await searchFormResponses(searchData, page);

    if (response?.data?.data) {
      let data = response.data.data;
      setSize(response.data.total_forms);
      let dataToDisplay = [];
      let exportableData = [];
      data.forEach((item) => {
        let data = [];
        let exportableTemp = [];
        data.push(item.full_name ? item.full_name : "");
        data.push(item.merchant ? item.merchant : "");
        data.push(item.outlet ? item.outlet : "");
        data.push(item.city ? item.city : "");
        data.push(item.brgy ? item.brgy : "");
        data.push(item.postal_code ? item.postal_code : "");
        data.push(item.floor_no ? item.floor_no : "");
        data.push(item.bldg ? item.bldg : "");
        data.push(item.coordinates ? item.coordinates : "");
        data.push(item.is_maya_merchant ? item.is_maya_merchant === "1" ? "Yes" : "No" : "");
        data.push(item.maya_merchant_sqr ? item.maya_merchant_sqr : "");
        data.push(item.maya_merchant_terminal ? item.maya_merchant_terminal : "");
        data.push(item.terminal_sn ? item.terminal_sn : "");
        data.push(item.sqr_store_code ? item.sqr_store_code : "");
        data.push(item.name ? item.name : "");
        data.push(item.number ? item.number : "");
        data.push(item.email ? item.email : "");
        data.push(item.maya_solutions ? item.maya_solutions : "");
        data.push(item.available_terminal_1 ? item.available_terminal_1 : "");
        data.push(item.available_terminal_2 ? item.available_terminal_2 : "");
        data.push(item.available_terminal_3 ? item.available_terminal_3 : "");
        data.push(item.available_terminal_4 ? item.available_terminal_4 : "");
        data.push(item.available_terminal_5 ? item.available_terminal_5 : "");
        data.push(item.available_terminal_6 ? item.available_terminal_6 : "");
        data.push(item.available_terminal_7 ? item.available_terminal_7 : "");
        data.push(item.available_terminal_8 ? item.available_terminal_8 : "");
        data.push(item.available_terminal_9 ? item.available_terminal_9 : "");
        data.push(item.available_terminal_10 ? item.available_terminal_10 : "");
        data.push(item.available_terminal_11 ? item.available_terminal_11 : "");
        data.push(item.available_terminal_12 ? item.available_terminal_12 : "");
        data.push(item.available_terminal_13 ? item.available_terminal_13 : "");
        data.push(item.available_terminal_14 ? item.available_terminal_14 : "");
        data.push(item.solution_device_1 ? item.solution_device_1 : "");
        data.push(item.solution_device_2 ? item.solution_device_2 : "");
        data.push(item.solution_device_3 ? item.solution_device_3 : "");
        data.push(item.solution_device_4 ? item.solution_device_4 : "");
        data.push(item.solution_device_5 ? item.solution_device_5 : "");
        data.push(item.solution_device_6 ? item.solution_device_6 : "");
        data.push(item.solution_device_7 ? item.solution_device_7 : "");
        data.push(item.solution_device_8 ? item.solution_device_8 : "");
        data.push(item.solution_device_9 ? item.solution_device_9 : "");
        data.push(item.solution_device_10 ? item.solution_device_10 : "");
        data.push(item.solution_device_11 ? item.solution_device_11 : "");
        data.push(item.maya_transaction ? item.maya_transaction : "");
        data.push(item.gcash_transaction ? item.gcash_transaction : "");
        data.push(item.cash_transaction ? item.cash_transaction : "");
        data.push(item.other_transaction_details ? item.other_transaction_details : "");
        data.push(item.other_transaction ? item.other_transaction : "");
        data.push(item.own_merchandising_1 ? item.own_merchandising_1 : "");
        data.push(item.own_merchandising_2 ? item.own_merchandising_2 : "");
        data.push(item.own_merchandising_3 ? item.own_merchandising_3 : "");
        data.push(item.own_merchandising_4 ? item.own_merchandising_4 : "");
        data.push(item.own_merchandising_5 ? item.own_merchandising_5 : "");
        data.push(item.own_merchandising_6 ? item.own_merchandising_6 : "");
        data.push(item.qrph_awareness ? item.qrph_awareness === "1" ? "Yes" : "No" : "");
        data.push(item.merchandising_restrictions ? item.merchandising_restrictions === "1" ? "Yes" : "No" : "");
        data.push(item.merchant_concern_1 ? item.merchant_concern_1 : "");
        data.push(item.merchant_concern_2 ? item.merchant_concern_2 : "");
        data.push(item.merchant_concern_3 ? item.merchant_concern_3 : "");
        data.push(item.merchant_concern_4 ? item.merchant_concern_4 : "");
        data.push(item.merchant_concern_5 ? item.merchant_concern_5 : "");
        data.push(item.merchant_concern_6 ? item.merchant_concern_6 : "");
        data.push(item.merchant_concern_7 ? item.merchant_concern_7 : "");
        data.push(item.merchant_concern_8 ? item.merchant_concern_8 : "");
        data.push(item.merchant_concern_9 ? item.merchant_concern_9 : "");
        data.push(item.merchant_concern_10 ? item.merchant_concern_10 : "");
        data.push(item.merchant_concern_11 ? item.merchant_concern_11 : "");
        data.push(item.merchant_concern_12 ? item.merchant_concern_12 : "");
        data.push(item.servicing_1 ? item.servicing_1 : "");
        data.push(item.servicing_2 ? item.servicing_2 : "");
        data.push(item.servicing_3 ? item.servicing_3 : "");
        data.push(item.servicing_4 ? item.servicing_4 : "");
        data.push(item.servicing_5 ? item.servicing_5 : "");
        data.push(item.servicing_6 ? item.servicing_6 : "");
        data.push(item.servicing_7 ? item.servicing_7 : "");
        data.push(item.servicing_8 ? item.servicing_8 : "");
        data.push(item.servicing_9 ? item.servicing_9 : "");
        data.push(item.replacement_1 ? item.replacement_1 : "");
        data.push(item.replacement_2 ? item.replacement_2 : "");
        data.push(item.replacement_3 ? item.replacement_3 : "");
        data.push(item.replacement_4 ? item.replacement_4 : "");
        data.push(item.merchandising_request_1 ? item.merchandising_request_1 : "");
        data.push(item.merchandising_request_2 ? item.merchandising_request_2 : "");
        data.push(item.merchandising_request_3 ? item.merchandising_request_3 : "");
        data.push(item.merchandising_request_4 ? item.merchandising_request_4 : "");
        data.push(item.merchandising_request_5 ? item.merchandising_request_5 : "");
        data.push(item.merchandising_request_6 ? item.merchandising_request_6 : "");
        data.push(item.merchandising_request_7 ? item.merchandising_request_7 : "");
        data.push(item.retraining_concern ? item.retraining_concern === "1" ? "Yes" : "No" : "");
        data.push(item.is_resolved ? item.is_resolved === "1" ? "Yes" : "No" : "");
        data.push(item.escalation ? item.escalation : "");
        data.push(item.added_on ? formatDateWithTime(item.added_on) : "");
        dataToDisplay.push(data);

      });
      setData(dataToDisplay);
    } else {
      setData([]);
      let responseMessage = response?.data?.messages?.error;
      setMessage({
        message: responseMessage ?? "Something went wrong.",
        type: "danger",
      });
      setShowToast(true);
    }
  };
  // console.log(data)

  async function handleExport() {
    const response = await exportFormResponses(searchData);
    console.log(response)
    console.log(response.data.url)
    if (response.data) {
      window.open(response.data.url, "_blank");
    }
  }


  const handlePaginateSearch = async (pagecount) => {
    const response = await searchFormResponses(searchData, pagecount);

    if (response?.data?.data) {
      setSize(response.data.total_forms);
      let data = response.data.data;
      let dataToDisplay = [];
      let exportableData = [];
      data.forEach((item) => {
        let data = [];
        let exportableTemp = [];
        data.push(item.full_name ? item.full_name : "");
        data.push(item.merchant ? item.merchant : "");
        data.push(item.outlet ? item.outlet : "");
        data.push(item.city ? item.city : "");
        data.push(item.brgy ? item.brgy : "");
        data.push(item.postal_code ? item.postal_code : "");
        data.push(item.floor_no ? item.floor_no : "");
        data.push(item.bldg ? item.bldg : "");
        data.push(item.coordinates ? item.coordinates : "");
        data.push(item.is_maya_merchant ? item.is_maya_merchant === "1" ? "Yes" : "No" : "");
        data.push(item.maya_merchant_sqr ? item.maya_merchant_sqr : "");
        data.push(item.maya_merchant_terminal ? item.maya_merchant_terminal : "");
        data.push(item.terminal_sn ? item.terminal_sn : "");
        data.push(item.sqr_store_code ? item.sqr_store_code : "");
        data.push(item.name ? item.name : "");
        data.push(item.number ? item.number : "");
        data.push(item.email ? item.email : "");
        data.push(item.maya_solutions ? item.maya_solutions : "");
        data.push(item.available_terminal_1 ? item.available_terminal_1 : "");
        data.push(item.available_terminal_2 ? item.available_terminal_2 : "");
        data.push(item.available_terminal_3 ? item.available_terminal_3 : "");
        data.push(item.available_terminal_4 ? item.available_terminal_4 : "");
        data.push(item.available_terminal_5 ? item.available_terminal_5 : "");
        data.push(item.available_terminal_6 ? item.available_terminal_6 : "");
        data.push(item.available_terminal_7 ? item.available_terminal_7 : "");
        data.push(item.available_terminal_8 ? item.available_terminal_8 : "");
        data.push(item.available_terminal_9 ? item.available_terminal_9 : "");
        data.push(item.available_terminal_10 ? item.available_terminal_10 : "");
        data.push(item.available_terminal_11 ? item.available_terminal_11 : "");
        data.push(item.available_terminal_12 ? item.available_terminal_12 : "");
        data.push(item.available_terminal_13 ? item.available_terminal_13 : "");
        data.push(item.available_terminal_14 ? item.available_terminal_14 : "");
        data.push(item.solution_device_1 ? item.solution_device_1 : "");
        data.push(item.solution_device_2 ? item.solution_device_2 : "");
        data.push(item.solution_device_3 ? item.solution_device_3 : "");
        data.push(item.solution_device_4 ? item.solution_device_4 : "");
        data.push(item.solution_device_5 ? item.solution_device_5 : "");
        data.push(item.solution_device_6 ? item.solution_device_6 : "");
        data.push(item.solution_device_7 ? item.solution_device_7 : "");
        data.push(item.solution_device_8 ? item.solution_device_8 : "");
        data.push(item.solution_device_9 ? item.solution_device_9 : "");
        data.push(item.solution_device_10 ? item.solution_device_10 : "");
        data.push(item.solution_device_11 ? item.solution_device_11 : "");
        data.push(item.maya_transaction ? item.maya_transaction : "");
        data.push(item.gcash_transaction ? item.gcash_transaction : "");
        data.push(item.cash_transaction ? item.cash_transaction : "");
        data.push(item.other_transaction_details ? item.other_transaction_details : "");
        data.push(item.other_transaction ? item.other_transaction : "");
        data.push(item.own_merchandising_1 ? item.own_merchandising_1 : "");
        data.push(item.own_merchandising_2 ? item.own_merchandising_2 : "");
        data.push(item.own_merchandising_3 ? item.own_merchandising_3 : "");
        data.push(item.own_merchandising_4 ? item.own_merchandising_4 : "");
        data.push(item.own_merchandising_5 ? item.own_merchandising_5 : "");
        data.push(item.own_merchandising_6 ? item.own_merchandising_6 : "");
        data.push(item.qrph_awareness ? item.qrph_awareness === "1" ? "Yes" : "No" : "");
        data.push(item.merchandising_restrictions ? item.merchandising_restrictions === "1" ? "Yes" : "No" : "");
        data.push(item.merchant_concern_1 ? item.merchant_concern_1 : "");
        data.push(item.merchant_concern_2 ? item.merchant_concern_2 : "");
        data.push(item.merchant_concern_3 ? item.merchant_concern_3 : "");
        data.push(item.merchant_concern_4 ? item.merchant_concern_4 : "");
        data.push(item.merchant_concern_5 ? item.merchant_concern_5 : "");
        data.push(item.merchant_concern_6 ? item.merchant_concern_6 : "");
        data.push(item.merchant_concern_7 ? item.merchant_concern_7 : "");
        data.push(item.merchant_concern_8 ? item.merchant_concern_8 : "");
        data.push(item.merchant_concern_9 ? item.merchant_concern_9 : "");
        data.push(item.merchant_concern_10 ? item.merchant_concern_10 : "");
        data.push(item.merchant_concern_11 ? item.merchant_concern_11 : "");
        data.push(item.merchant_concern_12 ? item.merchant_concern_12 : "");
        data.push(item.servicing_1 ? item.servicing_1 : "");
        data.push(item.servicing_2 ? item.servicing_2 : "");
        data.push(item.servicing_3 ? item.servicing_3 : "");
        data.push(item.servicing_4 ? item.servicing_4 : "");
        data.push(item.servicing_5 ? item.servicing_5 : "");
        data.push(item.servicing_6 ? item.servicing_6 : "");
        data.push(item.servicing_7 ? item.servicing_7 : "");
        data.push(item.servicing_8 ? item.servicing_8 : "");
        data.push(item.servicing_9 ? item.servicing_9 : "");
        data.push(item.replacement_1 ? item.replacement_1 : "");
        data.push(item.replacement_2 ? item.replacement_2 : "");
        data.push(item.replacement_3 ? item.replacement_3 : "");
        data.push(item.replacement_4 ? item.replacement_4 : "");
        data.push(item.merchandising_request_1 ? item.merchandising_request_1 : "");
        data.push(item.merchandising_request_2 ? item.merchandising_request_2 : "");
        data.push(item.merchandising_request_3 ? item.merchandising_request_3 : "");
        data.push(item.merchandising_request_4 ? item.merchandising_request_4 : "");
        data.push(item.merchandising_request_5 ? item.merchandising_request_5 : "");
        data.push(item.merchandising_request_6 ? item.merchandising_request_6 : "");
        data.push(item.merchandising_request_7 ? item.merchandising_request_7 : "");
        data.push(item.retraining_concern ? item.retraining_concern === "1" ? "Yes" : "No" : "");
        data.push(item.is_resolved ? item.is_resolved === "1" ? "Yes" : "No" : "");
        data.push(item.escalation ? item.escalation : "");
         data.push(item.added_on ? formatDateWithTime(item.added_on) : "");
        dataToDisplay.push(data);

      });
      setExportable({ items: exportableData, headers: headers });
      setData(dataToDisplay);
    } else {
      setData([]);
      let responseMessage = response?.data?.messages?.error;
      setMessage({
        message: responseMessage ?? "Something went wrong.",
        type: "danger",
      });
      setShowToast(true);
    }
  };

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row text-center mt-4">
        <div className="col-md-12">
          <p className="h1 title">FORMS</p>
        </div>
      </div>
      <div className="row mb-3">
        <div className="mt-3 col-12 col-sm-5">
          <InputGroup className="mb-sm-3">
            <Form.Control
              placeholder="Search"
              aria-label="Username"
              value={searchData.agent}
              onChange={(e) =>
                setSearchData({ ...searchData, agent: e.target.value })
              }
            />
          </InputGroup>
        </div>
        <div className="mt-3 col-12 col-sm-2">
          <DatePicker
            selected={searchData.dateFrom}
            onChange={(date) =>
              setSearchData({ ...searchData, dateFrom: date })
            }
            className="form-control"
          />
        </div>

        <div className="mt-3 col-12 col-sm-2">
          <DatePicker
            selected={searchData.dateTo}
            onChange={(date) => setSearchData({ ...searchData, dateTo: date })}
            className="form-control"
          />
        </div>

        <div className="mt-3 col-12 col-sm-3">
          <Button
            variant="primary"
            className="w-100 m-0"
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
      </div>
      <div className="row justify-content-center">

      <CustomTable
          size={size}
          items={data}
          // mapUrls={mapUrls}
          setItems={setData}
          headers={headers}
          itemsPerPage={10}
          title="Forms"
          exportable={exportable}
          photoIndex={9}
          beginningIndex={0}
          endIndex={10}
          handleExport={handleExport}
          type="form-report"
          setPage={setPage}
          handlePaginate={handlePaginateSearch}
        />

        {/* <CustomTable
          size={size}
          items={data}
          setItems={setData}
          headers={headers}
          itemsPerPage={10}
          title="Form"
          exportable={exportable}
          photoIndex={8}
          type="form-report"
          // type="regular"
          setPage={setPage}
          handlePaginate={handlePaginateSearch}
        /> */}
      </div>
      <CustomToast
        type={details.type}
        message={details.message}
        title="Form"
        show={showToast}
        setShow={setShowToast}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Viewing -{" "}
            {fileName.length > 15
              ? fileName.substring(0, 15) + "..."
              : fileName}
          </Modal.Title>
        </Modal.Header>
        <Image
          src={image}
          fallbackSrc="../../logo.png"
          alt="A Placeholder Image"
          style={{ maxHeight: width > 768 ? "500px" : "150px" }}
          className="img-fluid mx-auto"
        />
      </Modal>
    </div>
  );
}

export default FormManage;
